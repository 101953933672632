import request from './request'

const api = {
	// 列表
	lists: '/AppealCate/index',
	// 新增
	add: '/AppealCate/create',
	// 删除
	dele: '/AppealCate/delete',
	// 地区详情
	read: '/AppealCate/read',
	// 编辑
	edit: '/AppealCate/update',
}

export {
	request,
	api
}
